/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {BulkCheckout} from '../../../../../../actions/bulk-actions'
import {useAuth} from '../../../../auth'
import {getAllKids} from '../../../../../../actions/kids'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../../general-components/error-text/ErrorText'
import moment from 'moment-timezone'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion} from 'reactstrap'
import {getAttendanceByFilter} from '../../../../../../actions/attendace'
import {Link} from 'react-router-dom'
import {groupBy} from '../../../../../utils/common'
import {getAttendanceLocations} from '../../../../../../actions/location'
import {Avatar} from '@mui/material'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
type Props = {
  isUserLoading: boolean
}

const KidCheckoutModalForm: FC<Props> = ({isUserLoading}) => {
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const [showSweetAlert, setShowSweetAlert] = useState(false)
  const {filters, setKidsList, mobileConfiguration, attendanceLocations, setAttendanceLocations} =
    useAppContext()
  const {currentCompany} = useCompanyContext()
  const [kidsAttendance, setKidsAttendance] = useState<any>({})
  const [notifyContact, setNotifyContact] = useState(false)
  const company = window.location.pathname.split('/')[1]
  const intl = useIntl()
  const {currentUser} = useAuth()

  const [userForEdit] = useState({
    employee: '',
    date: new Date(),
  })

  const kidSchema = Yup.object().shape({
    employee: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })
  useEffect(() => {
    if (attendanceLocations?.length === 0 && currentCompany?.value) readAttendanceLocations()
  }, [])

  const cancel = () => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }
  const getkidsData = async () => {
    const temp = await getAllKids({
      filter: filters,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (temp) setKidsList(temp)
  }
  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidAttendanceIds: number[] = []
        selected.forEach((kid) => {
          if (kid?.attendance.length > 0) kidAttendanceIds.push(kid.attendance[0].kidAttendanceId)
        })

        if (kidAttendanceIds.length === 0) setShowSweetAlert(true)
        else {
          setShowSweetAlert(false)
          const data = {
            kidAttendanceIds,
            employeeId: currentUser?.employee?.employeeId,
            dateTimeOut: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
            employeeRemarks: values.employee,
            companyId: currentCompany?.value,
            mobileConfigurationId:
              mobileConfiguration?.KidAttendance[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkCheckout(data)

          if (!!resp) {
            await getkidsData()
            clearSelected()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            cancel()
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        clearSelected()
        reset()
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    if (selected.length > 0) readAttendace()
  }, [selected, formik.values])
  const reset = () => formik.resetForm()
  const readAttendace = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter !== '') filter += ` AND `

    filter += `dateTimeIn >= "${moment(formik.values.date)
      .startOf('day')
      .format('yyyy-MM-DDTHH:mm:ss')}" AND dateTimeIn<"${moment(formik.values.date)
      .endOf('day')
      .format('yyyy-MM-DDTHH:mm:ss')}" `

    const res = await getAttendanceByFilter(filter)
    // const temp = res.filter((i: any) => i.contactRemarks || i.employeeRemarks)

    const grouped = groupBy(res, 'kidId')

    setKidsAttendance(grouped)
  }

  const readAttendanceLocations = async () => {
    const resp = await getAttendanceLocations(`companyId=${currentCompany?.value}`)
    if (resp) setAttendanceLocations(resp)
  }
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {kidsAttendance && Object.keys(kidsAttendance)?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'-1'}>
                {Object.keys(kidsAttendance)?.map((key: any, index: number) => {
                  const fullKid: any = selected.find((i: any) => i.kidId === Number(key))
                  const Exists = kidsAttendance[key].find(
                    (item: any) =>
                      (item.employeeRemarks && item.employeeRemarks !== '') ||
                      (item.contactRemarks && item.contactRemarks !== '')
                  )
                  return (
                    Exists && (
                      <AccordionItem id={`${index}`}>
                        <AccordionHeader targetId={`${index}`}>
                          <div className='d-flex align-items-center'>
                            <Avatar
                              src={fullKid?.imagePath}
                              alt={`${fullKid?.firstName
                                ?.charAt(0)
                                ?.toUpperCase()} ${fullKid?.lastName?.charAt(0)?.toUpperCase()}`}
                              sx={{width: 16, height: 16}}
                            />
                            <h5 className='ms-2 my-0'> {fullKid.kidName}</h5>
                          </div>
                        </AccordionHeader>
                        <AccordionBody accordionId={`${index}`}>
                          <div className='d-flex flex-column w-100  '>
                            {kidsAttendance[key]?.map(
                              (meal: any, index1: number) =>
                                ((meal.employeeRemarks && meal.employeeRemarks !== '') ||
                                  (meal.contactRemarks && meal.contactRemarks !== '')) && (
                                  <div
                                    className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                                    key={index1}
                                  >
                                    <div>
                                      <h5 className='p-0 m-0'>
                                        {
                                          attendanceLocations.find(
                                            (i: any) =>
                                              i.attendanceLocationId === meal.attendanceLocationId
                                          )?.attendanceLocationName
                                        }
                                      </h5>

                                      {meal.employeeRemarks && (
                                        <p
                                          className='m-0 p-0 text-muted '
                                          dangerouslySetInnerHTML={{__html: meal.employeeRemarks}}
                                        ></p>
                                      )}
                                      {meal.contactRemarks && (
                                        <p
                                          className='m-0 p-0 text-muted '
                                          dangerouslySetInnerHTML={{__html: meal.contactRemarks}}
                                        ></p>
                                      )}
                                      <p className='m-0 p-0 text-muted'>
                                        {moment(meal.dateTimeIn).format('DD.MM.yyyy HH:mm')}
                                      </p>
                                    </div>
                                    <Link
                                      to={
                                        company
                                          ? `/${company}/kid-management/attendance`
                                          : '/kidling/kid-management/attendance'
                                      }
                                      className='btn btn-light p-1 px-4 mt-2'
                                    >
                                      <i className='fa-solid fa-arrow-right'></i>
                                    </Link>
                                  </div>
                                )
                            )}
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    )
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row mt-7'>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                dateTime
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
              />
            </div>
            {formik.touched.date && formik.errors.date && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.date}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='  form-label fs-6 fw-semibold text-muted'></label>
            <textarea
              {...formik.getFieldProps('employee')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.employee && formik.errors.employee},
                {
                  'is-valid': formik.touched.employee && !formik.errors.employee,
                }
              )}
              name='employee'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.EMPLOYEE_REMARKS'})}
            />
            {formik.touched.employee && formik.errors.employee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.employee}</span>
                </div>
              </div>
            )}
          </div>

          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              checked={notifyContact}
              onChange={() => setNotifyContact((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
            </span>
          </label>
          <div className='fv-row text-center mt-8  '>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting || isUserLoading}
            >
              {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
      <SweetAlert
        show={showSweetAlert}
        error
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.USER_NOT_CHECKED_IN_YET'})} />}
        onConfirm={(e) => setShowSweetAlert(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </>
  )
}

export {KidCheckoutModalForm}
